.custom-modal {
  width: 400px;
  border-radius: 4px;
  height: auto;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgb(121, 121, 121, 0.75) !important;
  display: flex !important;
  align-items: center !important;
  z-index: 100 !important;
}
