.react-datepicker__header {
  background-color: #fff;
}

.react-datepicker__day {
  color: #7C8EA7;
}

.react-datepicker__day--disabled {
  color: #ccc;
}

.react-datepicker__day--today {
  font-weight: bold;
}

.react-datepicker__day--selected {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}