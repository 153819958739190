.tooltip {
    display: block;
    text-align: left;
  }
  
  .tooltip .tooltiptext {
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    box-shadow: 0 4px 13px 2px rgba(121, 121, 121, 0.4);
    left: 25%;
    padding: 5px 10px;
    color: #424242;
    font-weight: normal;
    position: absolute;
    z-index: 99999;
    box-sizing: border-box;
    top: 25px;
    font-size: 0.75rem;
    border-radius: 10px;
    white-space: normal;
    transform: translate(-30%, -100%);
    max-width: 50vw;
    width: max-content;
    max-height: 6.2rem;
    height: auto;
    display: flex;
    display: -ms-grid;
  
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
  }
  
  .tooltiptext-inverted {
    transform: translateX(-30%) !important;
    top: 1.5rem !important;
  }
  
  .top-0px .tooltiptext {
    top: 0px;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }
  
  .tooltip .tooltiptext i {
    position: absolute;
    top: 100%;
    left: 30%;
    margin-left: -15px;
    width: 30px;
    min-height: 0.875rem;
    max-height: 2rem;
    overflow: hidden;
  }
  
  .tooltiparrow-inverted {
    display: none;
  }
  
  .tooltip .tooltiptext i::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(135deg);
    background-color: #ffffff;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  }
  
  .tooltip-scrollable-area {
    overflow-y: auto;
    height: inherit;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    max-width: 23rem;
  }
  
  .rc-tooltip-inner {
    background-color: #101426;
    min-height: 1rem !important;
    font-size: 0.85rem !important;
  }