@tailwind base;
@tailwind components;
@tailwind utilities;

.reportClass {
  width: 100%;
  height: 1000px;
}

@variants responsive {
  .w-1\/24 {
    width: 4.166667%;
  }
}

@variants responsive {
  .w-2\/24 {
    width: 8.33%;
  }
}

@variants responsive {
  .w-3\/24 {
    width: 12.5%;
  }
}

@variants responsive {
  .w-1\/36 {
    width: 2.777%;
  }
}

@variants responsive {
  .w-2\/36 {
    width: 5.5555%;
  }
}

@variants responsive {
  .w-3\/36 {
    width: 8.3333%;
  }
}

@variants responsive {
  .w-4\/36 {
    width: 11.1111%;
  }
}

@variants responsive {
  .w-5\/36 {
    width: 13.8889%;
  }
}

@variants responsive {
  .w-6\/36 {
    width: 16.6667%;
  }
}

@variants responsive {
  .w-7\/36 {
    width: 19.4443%;
  }
}

@variants responsive {
  .w-8\/36 {
    width: 22.222%;
  }
}
@variants responsive {
  .w-7\/72 {
    width: 9.722%;
  }
}
@variants responsive {
  .w-7\/10 {
    width: 70.0%;
  }
}
